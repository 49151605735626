export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {}
    };
  },
  mounted() {},
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {
      if (row) {
        this.Info = JSON.parse(row);
      } else {
        this.Info = {};
      }
    }
  }
};