import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    title: "评价详情",
    class: "info-div600",
    style: {
      "padding": "24px",
      "text-align": "left"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-width": "100px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "学校："
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.Info.active_name), 1)]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "科目："
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.Info.subject_name), 1)]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "题号："
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.Info.question_no), 1)]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "评价人："
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.Info.user_name), 1)]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "点赞评价："
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.Info.comment_type == 1 ? '好评' : '不好'), 1)]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "内容："
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($data.Info.content ? $data.Info.content : '无'), 1)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}